

export default class CardUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a || !b) {
      return 0;
    }
    if (a.createdDate().time() > b.createdDate().time()) {
      return -1;
    }
    if (a.createdDate().time() < b.createdDate().time()) {
      return 1;
    }
    return 0;
  }
  
  static SortByName = function(a,b) {
    if (a.name() > b.name()) {
      return 1;
    }
    if (a.name() < b.name()) {
      return -1;
    }
    return 0;
  }

  static SortByNameDesc = function(a,b) {
    if (a.name() < b.name()) {
      return 1;
    }
    if (a.name() > b.name()) {
      return -1;
    }
    return 0;
  }
}