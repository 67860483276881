
import RcDomainMap from "../../common/RcDomainMap.js";
import Card from './Card.js';
import CardUtils from "./CardUtils.js";

export default class CardMap extends RcDomainMap {
  static MODEL_NAME = "CardMap";

  constructor(domain, data) {
    super(domain, data);
  }

  getCard(key) {
    var value = super.get(key);
    if (value) {
      return new Card(this.domain, value);
    }
    return new Card(this.domain);
  }

  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getCard(key);
      values.push(item);
    }
    return values;
  }

  copy() {
    var data = this._copy();
    return new CardMap(this.domain, data);
  }

  sortByCreatedDate() {
    var list = this.list();
    return list.sort(CardUtils.SortByCreatedDate);
  }

  sort() {
    return this.sortByName();
  }

  sortByName() {
    var list = this.list();
    return list.sort(CardUtils.SortByName);
  }
}