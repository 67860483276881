import RcDomainObject from "../../common/RcDomainObject";

import User from "@/domain/model/user/User.js";
import RcDate from "@/domain/session/time/RcDate.js";
import DateUtils from "@/utils/DateUtils.js";
import StringUtils from "@/utils/StringUtils.js";
import CheckListMap from "../checklist/CheckListMap.js";
import Company from "../company/Company.js";
import ImageMap from "../image/ImageMap.js";
import EquipmentMap from "./EquipmentMap.js";

export default class Equipment extends RcDomainObject {

  static MODEL_NAME = "Equipment";

  static FIELDS = {
    NAME: "name",
    CHECKLIST_MAP: CheckListMap.MODEL_NAME,
    START_DATE: "serviceStartDate",
    END_DATE: "serviceEndDate",
    IMAGES: ImageMap.MODEL_NAME,
    COMPANY: Company.MODEL_NAME,
    DEFECT_TYPE: "defectType",
    SUMMARY: 'summary',
    SERIAL_NUMBER: 'vin',
    MAKE: 'make',
    MODEL: 'model',
    LAST_SAFETY_DATE: 'cvorDate',
    ANNUAL_NUMBER: 'cvorNumber',
    YEAR: 'year',
    COLOUR: 'colour',
    ODOMETER: 'odometer',
  }

  constructor(domain, data) {
    super(domain, data, Equipment.MODEL_NAME);
  }

  stateDisplay() {
    return StringUtils.toString(this.defectType(), "Good");
  }

  withLastSafetyDate(date) {
    this.put(Equipment.FIELDS.LAST_SAFETY_DATE, date);
    return this;
  }

  lastSafetyDate() {
    var dateValue = this.getString(Equipment.FIELDS.LAST_SAFETY_DATE);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(parseInt(dateValue)), timeZone);
    return rcDate;
  }

  serialNumber() {
    return this.getString(Equipment.FIELDS.SERIAL_NUMBER);
  }

  summary() {
    return this.getString(Equipment.FIELDS.SUMMARY);
  }

  colour() {
    return this.getString(Equipment.FIELDS.COLOUR);
  }

  make() {
    return this.getString(Equipment.FIELDS.MAKE);
  }

  model() {
    return this.getString(Equipment.FIELDS.MODEL);
  }

  name() {
    return this.getString(Equipment.FIELDS.NAME);
  }

  year() {
    return this.getString(Equipment.FIELDS.YEAR);
  }

  hours() {
    return this.getString(Equipment.FIELDS.ODOMETER);
  }

  withName(name) {
    this.put(Equipment.FIELDS.NAME, name);
    return this;
  }

  company() {
    var value = this.get(Equipment.FIELDS.COMPANY);
    return new Company(this.domain, value);
  }

  withCompany(value) {
    this.put(Equipment.FIELDS.COMPANY, value);
    return this;
  }

  defectType() {
    return this.getString(Equipment.FIELDS.DEFECT_TYPE);
  }

  withDefectType(value) {
    this.put(Equipment.FIELDS.DEFECT_TYPE, value);
    return this;
  }

  checklists() {
    var value = this.get(Equipment.FIELDS.CHECKLIST_MAP);
    return new CheckListMap(this.domain, value);
  }

  withChecklists(checklists) {
    this.put(Equipment.FIELDS.CHECKLIST_MAP, checklists);
    return this;
  }

  withImages(imageMap) {
    this.put(Equipment.FIELDS.IMAGES, imageMap);
    return this;
  }

  images() {
    return new ImageMap(this.domain, this.get(Equipment.FIELDS.IMAGES))
  }

  withStartDate(date) {
    this.put(Equipment.FIELDS.START_DATE, date);
    return this;
  }

  startDate() {
    return this.getString(Equipment.FIELDS.START_DATE);
  }

  withEndDate(date) {
    this.put(Equipment.FIELDS.END_DATE, date);
    return this;
  }

  endDate() {
    return this.getString(Equipment.FIELDS.END_DATE);
  }

  map() {
    const map = new EquipmentMap(this.domain);
    map.addEquipment(this);
    return map;
  }

  makeAndModel() {
    var value = "";
    var makeSet = false;
    if (StringUtils.isNotEmpty(this.make())) {
      value = this.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(this.model())) {
      if (makeSet) {
        value = value + " / " + this.model();
      } else {
        value = this.model();
      }
    }
    return value;
  }

  willLastSafetyDateExpireInWeeks(weeks) {
    return this.willAnnualDateExpireInWeeks(weeks);
  }

  willAnnualDateExpireInWeeks(weeks) {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return false;
    }
    var rcDate = this.annualDateV2();
    var rcMonth = rcDate.month();
    var lastDay = rcMonth.lastDay();
    var lastHour = lastDay.lastHour();
    var day = lastHour.day();
    var weeksAgo2 = day.weeksAgo(weeks);
    var nextYear = weeksAgo2.year().next();
    var now = this.domain.session().time().now();
    return nextYear.time() <= now.time();
  }

  annualDate() {
    return this.getString(Equipment.FIELDS.LAST_SAFETY_DATE);
  }

  annualDateV2() {
    var dateValue = this.annualDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(parseInt(dateValue)), timeZone);
    return rcDate;
  }

  hasLastSafetyDateExpired() {
    return this.hasAnnualDateExpired();
  }

  hasAnnualDateExpired() {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return false;
    }
    var daysRemaining = this.annualDateDaysLeft();
    var expired = daysRemaining <= 0;
    return expired;
  }

  annualDateDaysLeft() {
    var dateValue = this.annualDate();
    if (StringUtils.isEmpty(dateValue)) {
      return "";
    }
    var rcDate = this.annualDateV2();
    var rcMonth = rcDate.month();
    var lastDay = rcMonth.lastDay().lastHour().day();
    var nextYear = lastDay.year().next().day().year();
    var now = this.domain.session().time().now();
    var nowDay = now.day();
    var ms = nextYear.time() - nowDay.time();
    var daysRemaining = DateUtils.daysFromMs(ms);
    var daysLeft = Math.floor(daysRemaining);
    if (daysLeft < 0) {
      daysLeft = 0;
    }
    return daysLeft;
  }

  modifiedBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.MODIFIED_BY));
  }

  createdBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.CREATED_BY));
  }
}