import RcDomainObject from "@/domain/common/RcDomainObject.js";

import RcDate from '@/domain/session/time/RcDate.js';
import StringUtils from "@/utils/StringUtils.js";

import Company from "@/domain/model/company/Company.js";
import ReportMap from "@/domain/model/report/ReportMap.js";
import UserMap from "@/domain/model/user/UserMap.js";
import User from "@/domain/model/user/User.js";
import VehicleMap from "@/domain/model/vehicle/VehicleMap.js";

export default class Report extends RcDomainObject {

  static MODEL_NAME = "Report";

  static Fields = {
    NAME: "name",
    DESCRIPTION: "description",
    TEMPLATE_NAME: "templateName",
    COMPANY: "Company",
    START_TIME: "startTime",
    END_TIME: "endTime",
    USER_MAP: "UserMap",
    VEHICLE_MAP: "VehicleMap",
    TIMESHEET_MAP: "TimesheetMap",
    INSPECTION_MAP: "InspectionMap",
    LINKS: "links",
  }

  constructor(domain, data) {
    super(domain, data, Report.MODEL_NAME);
  }

  stateDisplay() {
    let state = this.state();
    if (StringUtils.startsWith(state, "active:run:done_run")) {
      return "Active (Results Ready)"
    }
    if (StringUtils.startsWith(state, "active:run")) {
      return "Active (Running)"
    }
    return null;
  }

  isStateActiveOrDone() {
    let state = this.state();
    if (StringUtils.startsWith(state, "active:run:done_run")) {
      return true;
    }
    if (StringUtils.eic(state, "active")) {
      return true;
    }
    return false;
  }

  withStateReadyToRun() {
    this.put("state", "active:run:ready_to_run");
    return this;
  }

  withCompany(company) {
    this.put(Report.Fields.COMPANY, company);
    return this;
  }

  company() {
    return new Company(this.domain, this.get(Report.Fields.COMPANY));
  }

  name() {
    return this.getString(Report.Fields.NAME);
  }

  description() {
    return this.getString(Report.Fields.DESCRIPTION);
  }

  createdDate() {
    var createdDate = super.createdDate();
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(createdDate), timeZone);
    return rcDate;
  }

  withTemplateName(name) {
    this.put(Report.Fields.TEMPLATE_NAME, name);
    return this;
  }

  withUsers(users) {
    this.put(Report.Fields.USER_MAP, users);
    return this;
  }

  users() {
    return new UserMap(this.domain, this.get(Report.Fields.USER_MAP));
  }

  withVehicles(vehicles) {
    this.put(Report.Fields.VEHICLE_MAP, vehicles);
    return this;
  }

  vehicles() {
    return new VehicleMap(this.domain, this.get(Report.Fields.VEHICLE_MAP));
  }

  withStartTime(rcDate) {
    this.put(Report.Fields.START_TIME, rcDate.time());
    this.put(RcDomainObject.DefaultFields.TIME_ZONE, rcDate.timeZone());
    return this;
  }

  startTime() {
    return this.getInt(Report.Fields.START_TIME);
  }

  startTimeV2() {
    let value = this.getInt(Report.Fields.START_TIME);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(value), timeZone);
    return rcDate
  }

  endTime() {
    return this.getInt(Report.Fields.END_TIME);
  }

  endTimeV2() {
    let value = this.getInt(Report.Fields.END_TIME);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(value), timeZone);
    return rcDate
  }


  withEndTime(rcDate) {
    this.put(Report.Fields.END_TIME, rcDate.time());
    return this;
  }

  links() {
    return this.get(Report.Fields.LINKS);
  }

  map() {
    const map = new ReportMap(this.domain);
    map.addReport(this);
    return map;
  }

  modifiedBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.MODIFIED_BY));
  }

  createdBy() {
    return new User(this.domain, this.get(RcDomainObject.DefaultFields.CREATED_BY));
  }

}