
import { SubDomain } from "../SubDomain.js";
import CardsDomain from "./CardsDomain.js";
import Invoice from "./Invoice.js";
import InvoiceItemsDomain from "./InvoiceItemsDomain.js";
import InvoiceSettingsDomain from "./InvoiceSettingsDomain.js";
import PaymentsDomain from "./PaymentsDomain.js";

export default class InvoicesDomain extends SubDomain {
  
  constructor(domain, storage, settings, cards, items, payments) {
    super(domain, Invoice.MODEL_NAME);
    this._storage = storage;
    this._settings = new InvoiceSettingsDomain(this.domain, settings);
    this._cards = new CardsDomain(this.domain, cards);
    this._items = new InvoiceItemsDomain(this.domain, items);
    this._payments = new PaymentsDomain(this.domain, payments);
  }
  
  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Invoice(this.domain, item);
      }
    }

    return new Invoice(this.domain);
  }
  
  settings() {
    return this._settings;
  }
  
  cards() {
    return this._cards;
  }

  items() {
    return this._items;
  }

  payments() {
    return this._payments;
  }
}