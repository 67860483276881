import RcDomainObject from "../common/RcDomainObject";

export class SubDomain extends RcDomainObject {
  constructor(domain, name) {
    super(domain, {});
    this._name = name;
  }

  hasFilter(filter) {
    return this.has(filter);
  }

  getFilter(filter) {
    return this.get(filter);
  }

  getFilterSize(filter) {
    return this.get(filter + "size");
  }
  
  putFilter(filter, response, total) {
    this.put(filter, response);
    this.put(filter + "size", total);
  }
}